// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant('.bg-gradient-#{$color}', $value);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient-shape-lush {
  background: #7dd56f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #56a849, #79e469);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #56a849, #79e469);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-slider-1 {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #56a849 50%, #79e469 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg-slider-2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg-slider-3 {
  animation-duration: 5s;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

.bg-area {
  position: relative;
  min-height: 100vh;
  background: #56a849;
  background: -webkit-linear-gradient(top, #5dc74d, #5ec54f, #80d673);
  width: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 0;

  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(230, 230, 230, 0.671);
    animation: animate 25s linear infinite;
    bottom: -150px;

    &:nth-child(1) {
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
    }

    &:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }

    &:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }

    &:nth-child(6) {
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
    }

    &:nth-child(7) {
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }

    &:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }

    &:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
    &:nth-child(11) {
      left: 85%;
      width: 10px;
      height: 10px;
      animation-delay: 5s;
      animation-duration: 11s;
    }
    &:nth-child(12) {
      left: 10%;
      width: 30px;
      height: 30px;
      animation-delay: 22s;
      animation-duration: 13s;
    }
    &:nth-child(13) {
      left: 46%;
      width: 90px;
      height: 90px;
      animation-delay: 13s;
      animation-duration: 34s;
    }
    &:nth-child(14) {
      left: 82%;
      width: 50px;
      height: 30px;
      animation-delay: 5s;
      animation-duration: 14s;
    }
  }
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
