.bg-gradient-gold {
  background: #ccab48;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(
    90deg,
    #ccab48 2.13%,
    rgba(248, 189, 11, 0.3) 44.47%,
    rgba(241, 182, 3, 0.56) 63.64%,
    rgba(221, 184, 70, 0.91) 91.91%
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    90deg,
    #ccab48 2.13%,
    rgba(248, 189, 11, 0.3) 44.47%,
    rgba(241, 182, 3, 0.56) 63.64%,
    rgba(221, 184, 70, 0.91) 91.91%
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-gradient-silver {
  background: #a5a5a5;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(
    90deg,
    #a5a5a5 2.13%,
    rgba(206, 206, 206, 0.46875) 49.83%,
    rgba(205, 205, 205, 0.49) 72.74%,
    rgba(186, 186, 186, 0.91) 91.91%
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    90deg,
    #a5a5a5 2.13%,
    rgba(206, 206, 206, 0.46875) 49.83%,
    rgba(205, 205, 205, 0.49) 72.74%,
    rgba(186, 186, 186, 0.91) 91.91%
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-gradient-bronze {
  background: #b75f19;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(
    90deg,
    #b75f19 2.13%,
    rgba(237, 157, 93, 0.75) 34.18%,
    rgba(208, 115, 40, 0.29) 55.14%,
    #cc742e 91.91%
  );
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    90deg,
    #b75f19 2.13%,
    rgba(237, 157, 93, 0.75) 34.18%,
    rgba(208, 115, 40, 0.29) 55.14%,
    #cc742e 91.91%
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.card-background {
  height: 15px;
  margin: 0px -1px -1px -1px;
  border-radius: 0px 0px 0.375rem 0.375rem;
}

.card-line-top {
  height: 2px;
  border-radius: 0.375rem 0.375rem 0px 0px;
}

.wrapper-chart {
  height: 500px !important;
}
