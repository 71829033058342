.DateInput_input {
  font-size: 0.875rem;
  line-height: 24px;
  color: #8898aa;
  width: 80%;
  border: 0;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  padding-right: 0rem;
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 6.8rem !important;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #8898aa;
  height: 24px;
  width: 1rem;
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px;
  border-radius: 6px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #6cbe5f;
  border: 1px double #61b154;
  color: #fff;
}

.CalendarDay__selected_span {
  background: #89eb7a;
  border: 1px double #7dd56f;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #89eb7a;
  border: 1px double #7dd56f;
  color: #fff;
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
  margin-left: -4px;
}

.DateRangePicker_picker {
  z-index: 999999;
}
