.mobile-app {
  position: relative;

  .iphone {
    position: relative;

    video {
      object-fit: cover;
      width: 11.6438rem;
      z-index: 0;
      height: 25rem;
      margin-top: 10px;
      cursor: pointer;
    }

    img {
      width: 17.735rem;
      height: 27.285rem;
      position: absolute;
      bottom: auto;
      text-align: center;
      z-index: 1;
      cursor: pointer;
    }
  }

  .android {
    position: relative;

    video {
      object-fit: cover;
      width: 12.6rem;
      z-index: 0;
      height: 26.1788rem;
      margin-top: 10px;
      border-radius: 10px;
      cursor: pointer;
    }

    img {
      width: 12.8844rem;
      height: 27.285rem;
      position: absolute;
      bottom: auto;
      text-align: center;
      z-index: 1;
      cursor: pointer;
    }
  }
}
