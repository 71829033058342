.header {
  position: absolute;
}

.app-enter {
  opacity: 0;
}
.app-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.app-exit {
  opacity: 1;
}
.app-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
