.react-tel-input .form-control {
  border: none;
  height: auto;
  width: auto;
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  -webkit-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
}

.react-tel-input .flag-dropdown {
  border: none;
}
