//
// Position
// modifier classes to be applied on an abosolute positioned element
// use it next to .position-absolute class
//

@each $size, $value in $spacers {
  .top-#{$size} {
    top: $value;
  }

  .right-#{$size} {
    right: $value;
  }

  .bottom-#{$size} {
    bottom: $value;
  }

  .left-#{$size} {
    left: $value;
  }
}

.center {
  left: 50%;
  transform: translateX(-50%);
}

.vh-90 {
  min-height: 90vh;
}
