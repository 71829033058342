.btn-circle.btn-xl {
  width: 4rem;
  color: #ccc;
  border-radius: 3.125rem;
  border: unset;
  margin: 0.25rem;
  height: 4rem;
  text-align: center;
  font-size: 1.125rem;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.144);
  @media screen and (max-width: 500px) {
    width: 3rem;
    height: 3rem;
    padding-left: 13px;
  }
}

.rc-steps-item-active .btn-circle,
.rc-steps-item-active .btn-circle:hover {
  background: linear-gradient(87deg, #a0d798 0, #a0d798 100%) !important;
  border-color: white;
  color: #fff;
}

.rc-steps {
  width: 100%;
  position: relative;
  top: -3.4375rem;
  padding: 0 1.25rem;
  @media screen and(max-width: 500px) {
    padding: 0;
  }
}

.rc-steps-label-vertical .rc-steps-item-icon {
  margin-left: 0rem;
  margin-right: 0rem;
}

.rc-steps-item-tail {
  top: 17px;
  padding: 0px;
  @media screen and(max-width: 500px) {
    display: none;
  }
  @media screen and(min-width: 1500px) {
    top: 23px;
  }
}

.rc-steps-item {
  font-size: 0.875rem;
}

.card-step-header {
  height: 5rem;

  @media screen and(max-width: 500px) {
    height: 4rem;
  }
}

.rc-steps-item-title {
  font-size: 0.675rem;
}

.rc-steps-label-vertical .rc-steps-item-content {
  width: auto;
  margin-top: 0px;
}
.rc-steps-item-finish .rc-steps-item-title:after {
  background: linear-gradient(87deg, #a0d798 0, #a0d798 100%) !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background: linear-gradient(87deg, #71b866 0, #a0d798 100%) !important;
}

.rc-steps-label-vertical .rc-steps-item-tail {
  padding: 0px 9px;
  margin-left: 48px;
}
