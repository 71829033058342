.main-content {
  position: relative;
  min-height: 100%;

  @media screen and (min-width: 768px) {
    margin-left: 64px;
  }

  // Navbar
  .navbar-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
      padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
    }
  }
}

// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  @each $breakpoint, $dimension in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        // Left
        &.fixed-left + .main-content {
          margin-left: $navbar-vertical-width;
        }

        // Right
        &.fixed-right + .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

.col-2-4 {
  @include make-col-ready(); // apply standard column margins, padding, etc.
  @include make-col(2.4); // 12/5 = 2.4
}

.col-sm-2-4 {
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(2.4);
  }
}

.col-md-2-4 {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col(2.4);
  }
}

.col-lg-2-4 {
  @include make-col-ready();

  @include media-breakpoint-up(lg) {
    @include make-col(2.4);
  }
}

.col-xl-2-4 {
  @include make-col-ready();

  @include media-breakpoint-up(xl) {
    @include make-col(2.4);
  }
}
