:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }

  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};

  font-size: 13px;
  line-height: 1.6;

  @media screen and (min-width: 500px) {
    font-size: 14px;
  }

  @media screen and (min-width: 570px) {
    font-size: 15px;
  }

  @media screen and (min-width: 620px) {
    font-size: 16px;
  }

  @media screen and (min-width: 800px) {
    font-size: 16px;
  }

  @media screen and (min-width: 920px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1000px) {
    font-size: 13.5px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 13.5px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 13.5px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 15.5px;
  }
}
