//
// Custom checkbox
//

.custom-checkbox {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-checked-border-color;
        }

        &::after {
          background-image: $custom-checkbox-indicator-icon-checked;
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }
}

.price-table {
  padding: 50px 30px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  background: #fff;
  text-align: center;
}

.price-title {
  text-transform: capitalize;
  font-weight: 400;
  color: $dark;
  font-size: 34px;
  margin-bottom: 20px;
}

.price-header {
  position: relative;
  z-index: 9;
}

.price-value {
  display: inline-block;
  margin-bottom: 20px;

  h2 {
    font-size: 25px;
    line-height: 25px;
    font-weight: 400;
    color: #03d665;
    margin-bottom: 0;
    position: relative;
    display: inline-block;

    span {
      font-weight: normal;
    }
  }

  > span {
    margin: 5px 0;
    display: block;
    color: #03d665;
  }
}

.price-list {
  ul li {
    position: relative;
    display: block;
    margin-bottom: 20px;
  }

  li i {
    color: #03d665;
    line-height: 20px;
    font-size: 20px;
  }
}

.price-table {
  &.active {
    padding: 80px 30px;
    z-index: 9;
  }

  &.style-2 {
    text-align: left;
    padding: 50px 30px;

    &:hover,
    &.active {
      background: #03d665;
      transition-duration: 1.5s;
      // transition-delay: 2s;
    }

    .price-title {
      color: #03d665;
      margin-bottom: 30px;
    }

    .price-value {
      position: absolute;
      right: -50px;
      top: -80px;
      margin-bottom: 0;

      h2 {
        color: $black;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        text-align: center;
        line-height: 150px;
        font-size: 35px;

        span {
          font-size: 20px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }

  &.style-4 .price-value h2 {
    color: $black;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-size: 34px;
  }

  &.style-2 {
    .price-value h2 {
      &:before,
      &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0 solid rgba(115, 41, 194, 0.05);
        border-radius: 100%;
        animation: da-pulse 1.5s ease-in-out 0s infinite;
      }

      @keyframes da-pulse {
        0%,
        100% {
          transform: scale(1.25);
          opacity: 0;
        }

        48%,
        52% {
          transform: scale(1);
          opacity: 1;
        }
      }

      &:before {
        background: rgba(115, 41, 194, 0.2);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:after {
        border-color: rgba(115, 41, 194, 0.05);
        border-width: 5px;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
      }
    }

    &:hover li,
    &.active li,
    &:hover .price-title,
    &.active .price-title,
    &:hover i,
    &.active i,
    &:hover .price-value h2,
    &.active .price-value h2 {
      color: white;
    }
  }
}
